import React from "react";
import Seo from "../components/Seo/seo";

import VideoList from "../components/VideoList/VideoList";

const WorldPage = () => {
  const videoArr = [
    "https://www.youtube.com/embed/K9YIq0oNiSM",
    "https://www.youtube.com/embed/u5CVsCnxyXg",
    "https://www.youtube.com/embed/dH3GSrCmzC8",
    "https://www.youtube.com/embed/cWGE9Gi0bB0",
  ];

  return (
    <>
      <Seo title="World Music" description="Selection of favourite music" />;
      <VideoList videos={videoArr} />
    </>
  );
};

export default WorldPage;
